import BlockContent from "@sanity/block-content-to-react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { FC } from "react"
import { Banner, BannerType } from "../components/banner"
import Layout from "../components/layout"
import LeftOverlay from "../components/left-overlay"
import { blockSerializer } from "../utils/sanity-helper"
import { dataset, projectId } from "../utils/sanityConfig"
import { getFluidImage } from "../utils/sanityImage"

export const query = graphql`
  query {
    frokostordning: sanityFrokostordning {
      id
      _rawMainImage
      mainImage {
        asset {
          fluid(maxWidth: 1152, maxHeight: 420) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawBody
      banners {
        link
        _rawImage
        image {
          asset {
            fluid(maxWidth: 576, maxHeight: 330) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        _rawIndhold
      }
    }

    senestemenu: allSanityMenu(
      sort: { fields: [aar, uge], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        uge
        aar
      }
    }
  }
`

const Frokostordning: FC<any> = (props) => {
  const aar = props.data.senestemenu.nodes[0].aar
  const uge = props.data.senestemenu.nodes[0].uge

  return (
    <Layout title="Frokostordning" aar={aar} uge={uge}>
      <LeftOverlay title="Frokostordning">
        <Img
          backgroundColor
          fluid={getFluidImage(
            props.data.frokostordning._rawMainImage,
            props.data.frokostordning.mainImage.asset.fluid,
            1152,
            420
          )}
        />
      </LeftOverlay>
      <BlockContent
        blocks={props.data.frokostordning._rawBody}
        serializers={blockSerializer()(
          <div className="px-8 mt-4 text-xl text-center grid max-w-3xl grid-cols-1 gap-4 px-8 mx-auto md:grid-cols-1 md:px-0" />,
          "normal"
        )}
        projectId={projectId}
        dataset={dataset}
      />

      {props.data.frokostordning.banners.map(
        (banner: BannerType, index: number) => (
          <Banner
            key={`frokostordning-banner-${index}`}
            banner={banner}
            imageRight={index % 2 !== 0}
          />
        )
      )}

      {/* <div className="mt-16">
        <BestilDagensRet aar={aar} uge={uge} />
      </div> */}
    </Layout>
  )
}

export default Frokostordning
